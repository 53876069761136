import Vue from 'vue'
import Router from 'vue-router'

// hack router push callback
const originalPush    = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) { //如果savedPosition存在，滚动条会自动跳到记录的值的地方
            return savedPosition
        } else {
            return {x: 0, y: 0}//savedPosition也是一个记录x轴和y轴位置的对象
        }
    },
    routes: [
        {path: '/', component: () => import('../page/index'), meta: {keepAlive: true}},
        {path: '/quick/wx', component: () => import('../page/wx')},
        {path: '/quick/qq', component: () => import('../page/qq')},

        {path: '/login', component: () => import('../page/user/login')},
        {path: '/user/openinfo', component: () => import('../page/user/openinfo')},

        {path: '/jszh', component: () => import('../page/jszh')},
        {path: '/user/sell', component: () => import('../page/sell')},
        // {path: '/list', component: () => import('../page/list')},
        {path: '/list', component: () => import('../page/list'), meta: {keepAlive: true}},
        {path: '/detail', component: () => import('../page/detail')},
        {path: '/user/confirmorder', component: () => import('../page/user/confirmorder')},
        {path: '/user/payment', component: () => import('../page/user/payment')},
        {path: '/user/wechat', component: () => import('../page/user/wechat')},
        {path: '/user/alipay', component: () => import('../page/user/alipay')},
        {path: '/staging', component: () => import('../page/staging')},
        {path: '/stagcalc', component: () => import('../page/stagcalc')},
        {path: '/stagform', component: () => import('../page/stagform')},
        {path: '/user', component: () => import('../page/user/index'), meta: {keepAlive: true}},
        {path: '/user', component: () => import('../page/user/index')},
        {path: '/user/orderlist', component: () => import('../page/user/orderlist')},
        {path: '/user/selllist', component: () => import('../page/user/selllist')},
        {path: '/user/setTradePass', component: () => import('../page/user/setTradePass')},
        // 协议
        {path: '/warning', component: () => import('../page/user/warning')},
        // 身份验证
        {path: '/user/identity', component: () => import('../page/user/identity')},
        {path: '/aciden', component: () => import('../page/user/aciden')},
        {path: '/user/history', component: () => import('../page/user/history')},
        {path: '/user/receipt', component: () => import('../page/user/receipt')},
        {path: '/user/mybid', component: () => import('../page/user/mybid')},
        {path: '/user/mycollect', component: () => import('../page/user/mycollect')},
        {path: '/user/bargaininf', component: () => import('../page/user/bargaininf')},
        {path: '/user/security', component: () => import('../page/user/security')},
        {path: '/user/usercard', component: () => import('../page/user/usercard')},
        {path: '/user/securityform', component: () => import('../page/user/securityform')},
        {path: '/user/pwdchange', component: () => import('../page/user/pwdchange')},
        {path: '/user/accmng', component: () => import('../page/user/accmng')},
        {path: '/user/insbill', component: () => import('../page/user/insbill')},
        {path: '/user/mygoods', component: () => import('../page/user/mygoods')},
        {path: '/user/sellform', component: () => import('../page/user/sellform')},
        {path: '/user/selldetail', component: () => import('../page/user/selldetail')},
        {path: '/user/buyorder', component: () => import('../page/user/buyorder')},
        {path: '/register', component: () => import('../page/user/register')},
        {path: '/dl', component: () => import('../page/sell/dl')},
        {path: '/baopei', component: () => import('../page/sell/baopei')},
        {path: '/dp', component: () => import('../page/sell/dp')},
        {path: '/est', component: () => import('../page/sell/est')},
        {path: '/fenqi', component: () => import('../page/sell/fenqi')},
        {path: '/gsq', component: () => import('../page/sell/gsq')},
        {path: '/jianjie', component: () => import('../page/sell/jianjie')},
        {path: '/kuafu', component: () => import('../page/sell/kuafu')},
        {path: '/recycling', component: () => import('../page/sell/recycling')},
        {path: '/news', component: () => import('../page/sell/news')},
        {path: '/stm', component: () => import('../page/sell/stm')},
        {path: '/stm2', component: () => import('../page/sell/stm2')},
        {path: '/stm3', component: () => import('../page/sell/stm3')},
        {path: '/stm4', component: () => import('../page/sell/stm4')},
        {path: '/stm5', component: () => import('../page/sell/stm5')},
        {path: '/stm6', component: () => import('../page/sell/stm6')},
        {path: '/strategy', component: () => import('../page/sell/strategy')},
        {path: '/zhaohao', component: () => import('../page/sell/zhaohao')},
        {path: '/zhongjie', component: () => import('../page/sell/zhongjie')},
        {path: '/zongzi', component: () => import('../page/sell/zongzi')},
        {path: '/goodslc', component: () => import('../page/goodslc')},
        {path: '/goodsqa', component: () => import('../page/goodsqa')},
        {path: '/search', component: () => import('../page/search')},
        {path: '/chongzhi', component: () => import('../page/sell/chongzhi')},
        {path: '/cbgfenqi', component: () => import('../page/sell/cbgfenqi')},
        {path: '/404', component: () => import('../page/404')},
        {path: '/chzz', component: () => import('../page/chzz')},
    ],
})
